.vstable {
  text-align: left;
  padding: 10px 10px 45px 10px;
}

.vstable__body {
  display: contents;
}

.vstable__row {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: auto;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  /* display: contents; */
}

.vstable__row:not(.vstable__row--header):hover .vstable__row__cell,
.vstable__row:nth-of-type(2n + 1):not(.vstable__row--header):hover .vstable__row__cell {
  background-color: rgb(241, 248, 255);
}

.vstable__row:nth-of-type(2n + 1):not(.vstable__row--header) .vstable__row__cell {
  background-color: rgb(228, 228, 228);
}

.vstable__row--header {
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid black;
  font-weight: 600;
  text-align: center;
}

.vstable__row--header .vstable__row__cell:hover {
  cursor: pointer;
  background-color: rgb(245, 244, 244);
}

.vstable__row--header .vstable__row__cell {
  border: none;
  user-select: none;
  text-align: center;
  flex: 1;
}

.vstable__row__cell {
  padding: 0.4rem;
  overflow: hidden;
  border: 1px solid rgb(206, 206, 206);
}
