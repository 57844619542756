.header {
  height: 65px;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.header__logo {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOMAAABMCAMAAACoGvBFAAAAHlBMVEVHcEz////////////////////////////////////JATChAAAACXRSTlMAdhdRL4/Oseqsa0vJAAAEYUlEQVR42uVbW3LDIAw0QkJw/wu3mTQxgB7YrmdSylebYIdl5dUi8LZ9QIucS+a4LdyoPButCzGWVwvLYkxvjGlZjPmNsfwDjHlZjPzGyMtixDdGXFhYn9Ga+wRJ/GiLpE0IxBRATpvrJpRtC8snzd0ZhPUhrosR8/IYofI+i2KE3cJ+BkbA+N0QbnHpv4MxvNtEnz4fR0p7VCUOYF798y3E2NwP+g5cQywcmva4BN//oZFzd2DkOylF42I7lifOESbUP4Ch4SgNHaols9xS4/6Sm15LoQ2rv13TXAGISR5D7lFWEJCFAfd+NRYXYzWi6Mc7Vv9oKxsQ1gXA+ig631ldLg+445EmMKK7VGmpDt6cCB1iNsdBMkZ3wNMYW5bs2IsKTfIaNo+otYHAAYwneKwCmpzYy/rjZimOC7HRglt4rIgET3EmrOEwBYMo5JRyH7x8L48ViOAqTvNBmlEczG32wlfWoyym7hFjYvppQdLV51fvmyVqWlCfIFFxeCAWJxSnVkduLwgVygwyxhTBSqAjE2FaCceq9P6lqTp9aqkjNVpGkyWMOc7WczyMYPgAEEjmYfKFAdDgJaPtpnHEmMAc7RGMAlemMYt6d+oUB4udTqtVEQ0YRYgnebR8gCi6WeU9dzciL5vGviIMxcnXJ3mshAFn0KvGfGA4uxY+dZe4HuMkj7rTltGA1p07UnyjOGQu8GblLI+qD1CikuVsM2CfKCX10+BiPMujuuZTxqiozpA5qRxo+WYeFR+g2rwsxuDggPgIxnIzj7IP0BfEJIHH4R7HMMLNPIpPnl7YQCmIafAG6QaM53kUfYCeByVjvudz2j6TRyEvGX6m+g6Nj/izeBQCk431iECaQO0dmnOBx1FgwCwQUD8B0iNara+j37bbeRxW8HYpdRBRSWpPbCdd5ZEO7W9l24el7mup+0z19ihGvIKxW99Gp8gYtFouShmpxI/gsUPFTrEYWtrl7nSYyJM88uzv1PkQ3EepQaV0x2JXNnFXHLjEo1MRlcMvuBc1qqN1T+auWV20w0s80qy6QaUb2T/NVKtOUrpHa2uw3iPga8/jPsfZOZq0zwZNiEXY/Smq3WsbkJqfbzd64NrzGO1inr2hkmZ6B1IFqq0hp1cNGUPrgcJFXW1Lls+qMzNzIsMHzIT3rjpZ7y7uBZSJvYBDPKpLHDZuoJZQreGLAjWxp8OX86O618qbSyTNJRtToFyQDJfzozAWdUT9dOBksjEF6twe6zEexxDUZz3NKo6kUer++Zm98oM8KtHK7jPmuUyejWz1zAPBr/hVNVzYe8bcE9txPrLxxNmVQzxuG4RcDiuE/5pIe+LHmxHi5gwSzpxBOtYBA712qXNOicnbSrrjxDYgxoi/epbsRHP3mhZoYVpx/m5L04rzZ9u/emcrw6oQaX0aY1mdRqAlD59jdfCK5934Hw3OI4uqJTCGbXmMa72zHWcXlothXO3N+yi8jrCtjpHWy/2xPdYcbIRfV5SlBn+bUJcAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  width: 113.5px;
  height: 50px;
  background-position: center;
  background-size: contain;
}

.header__nav {
  align-items: center;
}

.header__nav__list {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}

.header__nav__item {
  margin: 0 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.header__nav__item a {
  color: grey;
  text-decoration: none;
}
.header__nav__item a.active,
.header__nav__item a:hover {
  color: white;
}

@media screen and (max-width: 800px) {
  .header {
    background: white;
  }

  .header .header__logo {
    background-image: url('../../logo-black.png');
  }
}
