.search {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__input {
  width: 350px;
  height: 40px;
  font-size: 1.5rem;
  border-radius: 10px;
  outline: none;
  padding-left: 0.5rem;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .search {
    width: 850px;
    justify-content: flex-start;
    padding-left: 1rem;
  }
}
