.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: black;
  color: white;
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 20px;
  box-sizing: border-box;
}

.footer__prev-next,
.footer__count-indicator,
.footer__page-indicator {
  display: inline-block;
  padding-top: 3px;
  margin-left: 15px;
  font-size: 13px;
}
